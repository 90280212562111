@use "../variables" as *;
@use "../mixins" as *;

.flow-wrapper {
    margin-top: 5rem;
}
.flow-block {
    display: flex;
    gap: 6rem;
    position: relative;
    @include bp-down {
        gap: 2rem;
    }
    & + div {
        margin-top: 15rem;
        @include bp-down {
            margin-top: 8rem;
        }
        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-right: 20px solid transparent;
            border-left: 20px solid transparent;
            border-top: 35px solid $main-color;
            border-bottom: 0;
            top: -9rem;
            left: 0;
            right: 0;
            margin: auto;
            @include bp-down {
                top: -5rem;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                border-top: 20px solid $main-color;
            }
        }
    }
    @include bp-down {
        flex-direction: column;
    }
    &--rev {
        flex-direction: row-reverse;
    }
    &__thumb {
        width: 48rem;
        overflow: hidden;
        position: relative;
        border-radius: 3rem 2rem 2rem 2rem;
        @include bp-down {
            width: 100%;
        }
        .numb {
            position: absolute;
            background-color: #fff;
            color: $bright-color;
            font-size: 6rem;
            font-weight: bold;
            line-height: 1;
            width: 12rem;
            text-align: center;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            top: 0;
            left: 0;
            border-radius: 2rem 0 2rem 0;
            @include robot;
            @include bp-down {
                font-size: 5rem;
            }
        }
    }
    &__txt {
        width: 46rem;
        @include bp-down {
            width: 100%;
        }
    }
}