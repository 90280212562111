@use "../variables" as *;
@use "../mixins" as *;

.table-base {
    width: 100%;
    th,td {
        padding: 2rem 3rem;
        box-sizing: border-box;
        @include bp-down {
            padding: 1.5rem;
        }
    }
    th {
        background-color: $main-color;
        color: #fff;
        font-weight: bold;
        text-align: left;
        width: 20rem;
        @include bp-down {
            width: 100%;
            display: block;
        }
    }
    td {
        background-color: $bg-lightest;
        width: calc(100% - 30rem);
        @include bp-down {
            width: 100%;
            display: block;
        }
    }
}