@use "../variables" as *;
@use "../mixins" as *;

.kv {
    height: calc(100vh - 20rem);
    min-height: 60rem;
    max-height: 80rem;
    @include bp-down {
        height: 60rem;
        min-height: 36rem;
        max-height: 60rem;
    }
    &__txt {
        font-size: 6rem;
        font-weight: bold;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
        z-index: 5;
        text-shadow: 0 .2rem .2rem #fff;
        @include bp-down {
            font-size: 4rem;
        }
    }
    &__item {
        height: calc(100vh - 20rem);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.home-news {
    position: relative;
    &__inner {
        display: flex;
        align-items: center;
        position: absolute;
        top: -3rem;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
        border-radius: 1rem;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 .3rem .3rem rgba(000,000,000,.05);
        @include bp-down {
            flex-direction: column;
            position: relative;
        }
    }
    &__ttl {
        background-color: $main-color;
        width: 12rem;
        padding: 2rem 0;
        border-radius: 1rem 0 0 1rem;
        @include bp-down {
            width: 100%;
            border-radius: 1rem 1rem 0 0;
        }
        h2 {
            font-size: 1.4rem;
            font-weight: bold;
            color: #fff;
            text-align: center;
        }
    }
    &__list {
        background-color: #fff;
        width: calc(100% - 12rem);
        height: 6rem;
        overflow: hidden;
        @include bp-down {
            width: 100%;
        }
        li {
            a {
                display: flex;
                gap: 2rem;
                padding: 1.6rem 2rem;
                border-radius: 0 1rem 1rem 0;
                background-color: #fff;
                font-weight: bold;
                color: $txt-default;
                transition: .3s;
                position: relative;
                // width: 100%;
                &::after {
                    @include icon;
                    content: '\e80e';
                    position: absolute;
                    color: $main-color;
                    right: 3rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1.2rem;
                    @include bp-down {
                        right: 2rem;
                    }
                }
                .ttl {
                    width: 60rem;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    @include bp-down {
                        width: 21rem;
                    }
                }
                &:hover {
                    color: $main-color;
                    transition: .3s;
                }
            }
        }
    }
}

.home-about {
    .main-contents & {
        padding-top: 12rem;
        @include bp-down {
            padding-top: 0;
        }
    }
    &__thumb {
        display: flex;
        border-radius: 1rem;
        overflow: hidden;
        margin-top: 5rem;
    }
    &__link {
        margin-top: 6rem;
    }
}
.home-advantage {
    background-color: $bg-lightest;
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 6rem;
        @include bp-down {
            flex-direction: column;
            gap: 1rem;
        }
    }
    &__thumb {
        width: 48rem;
        border-radius: 1rem;
        overflow: hidden;
        @include bp-down {
            width: 100%;
            margin-top: 2rem;
        }
        img {
            object-fit: cover;
            width: 100%;
            aspect-ratio: 4/3;
        }
    }
    &__txt {
        width: calc(100% - 53rem);
        @include bp-down {
            width: 100%;
        }
    }
}
.loop-slider {
    .swiper-wrapper {
        transition-timing-function: linear;
    }
    .swiper-slide {
        img {
            aspect-ratio: 3/2;
            object-fit: cover;
            width: 100%;
        }
    }
}
.home-price {
    background-color: $bg-lightest;
    &__inner {
        display: flex;
        gap: 6rem;
        @include bp-down {
            flex-direction: column;
            gap: 1rem;
        }
    }
    &__thumb {
        width: 48rem;
        border-radius: 1rem;
        overflow: hidden;
        @include bp-down {
            width: 100%;
            margin-top: 2rem;
        }
        img {
            object-fit: cover;
            width: 100%;
            aspect-ratio: 4/3;
        }
    }
    &__txt {
        width: calc(100% - 53rem);
        width: 100%;
    }
}
.home-payment {
    &__card {
        padding: 5rem;
        border-radius: 1rem;
        background-color: $bg-lightest;
        margin-top: 6rem;
        @include bp-down {
            padding: 2rem;
            margin-top: 2rem;
        }
        ul {
            display: flex;
            gap: 4rem;
            @include bp-down {
                flex-wrap: wrap;
                gap: 2rem;
            }
            li {
                img {
                    display: block;
                    height: 4rem;
                    @include bp-down {
                        height: 2.5rem;
                    }
                }
            }
        }
    }
}
.home-news-latest {
    background-color: $bg-lightest;
    &__list {
        background-color: #fff;
        border-radius: 2rem;
        border: .1rem solid #ccc;
        overflow: hidden;
        margin-top: 4rem;
        li {
            & + li {
                border-top: .1rem solid #ccc;
            }
            a {
                display: flex;
                gap: 2rem;
                color: $txt-default;
                transition: .3s;
                padding: 3rem 5rem;
                @include bp-down {
                    flex-wrap: wrap;
                    padding: 2rem;
                    gap: 1.2rem;
                }
                &:hover {
                    background-color: $bg-lightest;
                    transition: .3s;
                }
                .ttl {
                    width: 60rem;
                    overflow: hidden;
                    font-weight: bold;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                .slug {
                    font-size: 1.1rem;
                    color: #fff;
                    padding: .3rem;
                    width: 8rem;
                    text-align: center;
                    font-weight: bold;
                    border-radius: .6rem;
                    @include bp-down {
                        width: 12rem;
                    }
                    &.topics {
                        background-color: $main-color;
                    }
                    &.works {
                        background-color: $bright-color;
                    }
                    &.other, &.big-trash, &.cleaning, &.setting, &.transport, &.all {
                        background-color: $bright-color;
                    }
                }
            }
        }
    }
}