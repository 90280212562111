@use "../variables" as *;
@use "../mixins" as *;

.contact-conv-area {
    background: rgb(238,255,241);
    background: linear-gradient(90deg, rgba(238,255,241,1) 0%, rgba(238,255,241,1) 50%, rgba(246,251,255,1) 50%, rgba(246,251,255,1) 100%);
    margin-top: 6rem;
    @include bp-down {
        margin-top: 4rem;
        padding: 0;
    }
    &__inner {
        display: flex;
        @include bp-down {
            flex-direction: column;
        }
    }
    &__block {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6rem;
        padding-bottom: 6rem;
        @include bp-down {
            width: 100%;
        }
        &.line {
            background-color: #eefff1;
        }
        &.tel {
            background-color: $bg-lightest;
        }
        h3 {
            text-align: center;
        }
    }
    &__btn {
        margin-top: 2rem;
        &.line {
            background-color: $accent-color;
        }
    }
}
.contact-form-table {
    margin-top: 6rem;
    @include bp-down {
        margin-top: 4rem;
    }
}
.thanks-ttl {
    margin-top: 4rem;
    font-size: 2rem;
    font-weight: bold;
}
.thanks-txt {
    margin-top: 2rem;
}