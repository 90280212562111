@use "../variables" as *;
@use "../mixins" as *;

.about-company {
    margin-top: 5rem;
    &__table {
        margin-top: 4rem;
    }
    &__map {}
}
.access-map {
    margin-top: 5rem;
    &__map {
        overflow: hidden;
        border-radius: 2rem;
        margin-top: 4rem;
        iframe {
            vertical-align: bottom;
            height: 60rem;
            @include bp-down {
                height: 30rem;
            }
        }
    }
}
.office-outline {
    margin-top: 5rem;
    &__block {
        display: flex;
        margin-top: 4rem;
        @include bp-down {
            flex-direction: column;
            gap: 1.5rem;
        }
    }
    &__thumb {
        width: 50%;
        @include bp-down {
            width: 100%;
        }
    }
}