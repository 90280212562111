
$main-color: #008BF2;

$main-dark: #006FC1;

$txt-default: #222;

$bright-color: #EC8E00;

$accent-color: #00BC26;

$bg-lightest: #F6FBFF;