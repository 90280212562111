@use "../variables" as *;
@use "../mixins" as *;

.list-dots {
    >li {
        position: relative;
        padding-left: 2rem;
        &::before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            background-color: $main-color;
            border-radius: 3rem;
            left: 0;
            top: 1rem;
        }
    }
}
.sitemap-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 4rem;
    li {
        width: calc((100% / 3) - 1.4rem);
        @include bp-down {
            width: 100%;
        }
        a {
            display: block;
            background-color: $bg-lightest;
            border-radius: 1rem;
            padding: 2rem 2rem 2rem 4rem;
            color: #222;
            position: relative;
            &:hover {
                background-color: $main-color;
                color: #fff;
                &::before {
                    border-left: 10px solid #fff;
                }
            }
            &::before {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 10px solid $main-dark;
                border-right: 0;
                top: 50%;
                left: 2rem;
                transform: translateY(-50%);
            }
        }
    }
}