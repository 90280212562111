@use "../variables" as *;
@use "../mixins" as *;

.advantage-features {
    &__block {
        display: flex;
        gap: 7rem;
        @include bp-down {
            flex-direction: column;
            gap: 3rem;
        }
        &--rev {
            flex-direction: row-reverse;
            @include bp-down {
                flex-direction: column;
            }
        }
        & + div {
            margin-top: 8rem;
        }
    }
    &__thumb {
        width: 48rem;
        border-radius: 2rem;
        overflow: hidden;
        @include bp-down {
            width: 100%;
        }
    }
    &__txt {
        width: 45rem;
        @include bp-down {
            width: 100%;
        }
    }
}