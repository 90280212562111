@use "../variables" as *;
@use "../mixins" as *;

.ttl1 {
    font-size: 3.6rem;
    font-weight: bold;
    position: relative;
    color: $main-color;
    @include bp-down {
        font-size: 3rem;
    }
    >span {
        display: inline-block;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 6rem;
            height: .3rem;
            top: 50%;
            right: -8rem;
            transform: translateY(-50%);
            background-color: $bright-color;
        }
    }
    &--mono-bar {
        color: #fff;
        >span {
            &::after {
                background-color: #fff;
            }
        }
    }
    & + * {
        margin-top: 3rem;
        @include bp-down {
            margin-top: 2rem;
        }
    }
}

.ttl2 {
    border-bottom: .3rem solid $main-color;
    padding-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.8rem;
}
.ttl-slash {
    font-size: 3rem;
    color: $main-color;
    font-weight: bold;
    padding-left: 4.5rem;
    position: relative;
    @include bp-down {
        font-size: 2rem;
    }
    &::before, &::after {
        content: "";
        position: absolute;
        width: .3rem;
        height: 3rem;
        transform: rotate(45deg);
        background-color: $bright-color;
        top: 1.4rem;
        @include bp-down {
            height: 2rem;
            top: 1rem;
        }
    }
    &::before {
        left: 1rem;
    }
    &::after {
        left:2rem;
    }
    & + * {
        margin-top: 5rem;
        @include bp-down {
            margin-top: 2rem;
        }
    }
}
.ttl-dots {
    font-size: 2rem;
    font-weight: bold;
    padding-left: 3.5rem;
    position: relative;
    @include bp-down {
        font-size: 1.8rem;
    }
    &::before, &::after {
        content: "";
        position: absolute;
        background-color: $bright-color;
    }
    &::before {
        left: 1rem;
        top:  1rem;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 10rem;
    }
    &::after {
        left: 0em;
        top: 2rem;
        width: .7rem;
        height: .7rem;
        border-radius: 10rem;
    }
    & + * {
        margin-top: 3rem;
        @include bp-down {
            margin-top: 2rem;
        }
    }
}
.ttl-sub {
    font-size: 3rem;
    font-weight: bold;
    font-size: 2rem;
    & + * {
        margin-top: 2rem;
        @include bp-down {
            margin-top: 1.5rem;
        }
    }
}
.ttl-border {
    position: relative;
    font-weight: bold;
    font-size: 2.4rem;
    padding-left: 3rem;
    line-height: 1.4;
    &::before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 100%;
        left: 0;
        top: 0;
        background: rgb(0,139,242);
        background: linear-gradient(0deg, rgba(0,139,242,1) 0%, rgba(0,139,242,1) 50%, rgba(236,142,0,1) 50%, rgba(236,142,0,1) 100%);
    }
}