
@use "../variables" as *;

@mixin bp-up {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin bp-down {
    @media screen and (max-width: 767px) {
        @content;
    }
}
