@use "../variables" as *;
@use "../mixins" as *;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.5rem;
    line-height: 1.8;
    @include bp-down {
        font-size: 1.5rem;
    }
}

a {
    text-decoration: none;
    transition: .3s;
    &:hover {
        opacity: .8;
    }
}

img {
    vertical-align: bottom;
    max-width: 100%;
    height: auto;
}

div,section,header,footer,h1,h2,h3,h4,h5,h6,p,main {
    box-sizing: border-box;
}
ul, ol {
    list-style-type: none;
}

.inline-main {
    color: $main-color;
}
.inline-bright {
    color: $bright-color;
}
.inline-bold {
    font-weight: bold;
}
@include bp-up {
    .pc-none {
        display: none;
    }
}
@include bp-down {
    .sp-none {
        display: none;
    }
}