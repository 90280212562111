/*!
Theme Name: kaisoku theme ver 2.1
Theme URI: https://benriya24h.com/
Author: amplededign office
Author URI: https://ampledesign.com
Version: 2.1
*/

@charset "utf-8";

@use "reset";
@use "common";

@use "pages";
@use "components";