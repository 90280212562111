@use "../variables" as *;
@use "../mixins" as *;

.service-wrapper {
    margin-top: 8rem;
    @include bp-down {
        margin-top: 5rem;
    }
}

.service {
    &__block {
        & + div {
            margin-top: 8rem;
            @include bp-down {
                margin-top: 6rem;
            }
        }
        &__inner {
            display: flex;
            gap: 4rem;
            margin-top: 4rem;
            align-items: flex-start;
            @include bp-down {
                flex-direction: column;
                gap: 3rem;
                margin-top: 3rem;
            }
        }
        &__thumb {
            width: 30rem;
            border-radius: 2rem;
            overflow: hidden;
            @include bp-down {
                width: 100%;
            }
        }
        &__txt {
            width: 66rem;
            @include bp-down {
                width: 100%;
            }
            &__price {
                font-size: 1.8rem;
                font-weight: bold;
                margin-top: 1rem!important;
            }
            &__catch {
                margin-top: 2rem;
            }
            .list-dots {
                margin-top: 2rem;
            }
        }
    }
    &__btn {
        margin-top: 4rem;
        height: 5rem!important;
        padding-top: 2rem!important;
        @include bp-down {
            margin: 3rem auto 0 auto;
        }
    }
}
.work-scene {
    margin-top: 8rem;
    @include bp-down {
        margin-top: 4rem;
    }
    &__thumb {
        margin-top: 6rem;
        @include bp-down {
            margin-top: 3rem;
        }
    }
    &__txt {
        margin-top: 4rem;
    }
    &__conv-link {
        margin-top: 6rem;
        @include bp-down {
            margin-top: 4rem;
        }
    }
}
.service-conv-btn {
    display: flex;
    justify-content: space-between;
    @include bp-down {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    &__item {
        &__link {
            height: 4.5rem!important;
            &.line {
                background-color: $accent-color;
            }
            &.tel {
                background-color: $bright-color;
            }
        }
    }
}