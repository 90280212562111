@use "../variables" as *;
@use "../mixins" as *;

.blog-container {
    border-radius: 2rem;
    border: .1rem solid #ccc;
    padding: 6rem;
    @include bp-down {
        padding: 2rem;
    }
    &__content {
        margin-top: 6rem;
        @include bp-down {
            margin-top: 3rem;
        }
        *:not(:first-child) {
            margin-top: 4rem;
        }
        &:first-child {
            margin-top: 0;
        }
        >img {
            width: 100%;
        }
    }
}
.blog-header {
    &__info {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-top: 2rem!important;
    }
    &__date {
        font-weight: bold;
        font-size: 1.8rem;
        @include bp-down {
            font-size: 1.4rem;
        }
    }
    &__cat {
        background-color: $main-color;
        color: #fff;
        font-size: 1.2rem;
        width: 10rem;
        text-align: center;
        padding: .5rem 0;
        border-radius: .4rem;
        font-weight: bold;
        &.works {
            background-color: $bright-color;
        }
    }
}
.blog-arc {
    background-color: $bg-lightest;
    padding-top: 12rem;
    padding-bottom: 12rem;
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem 2rem;
        @include bp-down {
            flex-direction: column;
        }
        &__item {
            border-radius: 2rem;
            overflow: hidden;
            position: relative;
            width: calc((100% - 4rem) / 3);
            @include bp-down {
                width: 100%;
            }
            a {
                display: block;
                .date {
                    background-color: #fff;
                    color: $main-color;
                    font-weight: bold;
                    width: 12rem;
                    height: 4rem;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 2rem 0 2rem 0;
                    padding-top: .5rem;
                }
            }
        }
        &__thumb {
            img {
                width: 100%;
                aspect-ratio: 3 / 2;
                height: 20rem;
                object-fit: cover;
            }
        }
        &__txt {
            background-color: #fff;
            padding: 2rem;
            .slug {
                font-size: 1.1rem;
                color: #fff;
                padding: 0.3rem;
                width: 8rem;
                text-align: center;
                font-weight: bold;
                border-radius: 0.6rem;
                &.works {
                    background-color: $bright-color;
                }
                &.topics {
                    background-color: $main-color;
                }
                &.other, &.big-trash, &.cleaning, &.setting, &.transport, &.all {
                    background-color: $bright-color;
                }
            }
            .ttl {
                width: 28rem;
                overflow: hidden;
                font-weight: bold;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                color: $txt-default;
                margin-top: 1rem;
            }
        }
    }
}
.x-share-btn {
    display: block;
    margin-left: auto;
    width: 5rem;
    height: 5rem;
    transition: .3s;
    &:hover {
        opacity: .7;
        transition: .3s;
    }
}