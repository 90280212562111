@use "../variables" as *;
@use "../mixins" as *;

.faq-block {
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 2rem;
    border: .1rem solid #ccc;
    @include bp-down {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    & + div {
        margin-top: 6rem;
        @include bp-down {
            margin-top: 3rem;
        }
    }
    &__question {
        position: relative;
        font-size: 1.8rem;
        font-weight: bold;
        padding: 3.5rem 2rem 3.5rem 8rem;
        @include bp-down {
            padding: 2rem 2rem 2rem 5rem;
            font-size: 1.4rem;
        }
        &::before {
            content: "Q";
            position: absolute;
            display: block;
            background-color: $main-color;
            color: #fff;
            width: 5rem;
            height: 5rem;
            border-radius: 5rem;
            text-align: center;
            overflow: hidden;
            font-size: 1.8rem;
            font-weight: bold;
            padding-top: 1.4rem;
            line-height: 1;
            box-sizing: border-box;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @include bp-down {
                width: 3rem;
                height: 3rem;
                padding-top: .6rem;
                font-size: 1.4rem;
            }
        }
    }
    &__answer {
        position: relative;
        font-size: 1.8rem;
        font-weight: bold;
        padding: 3.5rem 2rem 3.5rem 10rem;
        border-top: .1rem solid #ccc;
        @include bp-down {
            padding: 2rem 2rem 2rem 5rem;
            font-size: 1.4rem;
        }
        &::before {
            content: "A";
            position: absolute;
            display: block;
            background-color: $bright-color;
            color: #fff;
            width: 5rem;
            height: 5rem;
            text-align: center;
            overflow: hidden;
            font-size: 1.8rem;
            font-weight: bold;
            padding-top: 1.4rem;
            line-height: 1;
            box-sizing: border-box;
            left: 2rem;
            // top: 50%;
            // transform: translateY(-50%);
            @include bp-down {
                width: 3rem;
                height: 3rem;
                padding-top: .6rem;
                left: 0;
                font-size: 1.4rem;
            }
        }
    }
}