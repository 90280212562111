
@use "../mixins" as *;

.container {
    padding-left: 2rem;
    padding-right: 2rem;
}
.sec {
    position: relative;
    &--lv1 {
        padding-top: 10rem;
        padding-bottom: 10rem;
        @include bp-down {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
}
.sec-inner {
    width: 100rem;
    margin-left: auto;
    margin-right: auto;
    @include bp-down {
        width: 100%;
    }
}
.price-guide {
    background-color: #FFF4E4;
    border-radius: 2rem;
    padding: 4rem 6rem;
    margin-top: 6rem;
    @include bp-down {
        padding: 2rem;
    }
    &__txt {
        font-size: 2rem;
        font-weight: bold;
        color: #FF477F;
        position: relative;
        padding-left: 4rem;
        line-height: 1.4;
        @include bp-down {
            font-size: 1.4rem;
        }
        &::before {
            content: '\e810';
            @include icon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: normal;
            font-size: 2.4;
        }
    }
}
input[type="text"], input[type="email"], select, textarea {
    background-color: #fff;
    border: .1rem solid #e5e5e5;
    padding: 1rem;
}
input[type="text"], input[type="email"], select, textarea {
    width: 100%;
    display: block;
    box-sizing: border-box;
}
.mwform-radio-field {
    label {
        display: flex;
        gap: 1rem;
        align-items: center;
    }
}
.btn.contact-submit {
    padding-bottom: 1.5rem;
    display: block;
    height: auto;
    margin-top: 6rem;
}