@use "../variables" as *;
@use "../mixins" as *;

.site-wrapper {
    overflow: hidden;
}
.fixed-btn {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1rem 0 0 1rem;
    z-index: 3;
    overflow: hidden;
    a {
        display: block;
        color: #fff;
        line-height: 1;
        font-size: 2rem;
        padding: 1.6rem;
        i {
            font-size: 2.8rem;
        }
    }
    .line-btn {
        background-color: $accent-color;
    }
    .mail-btn {
        background-color: $bright-color;
    }
}
.site-header {
    padding: 2rem;
    border-top: .5rem solid $main-color;
    &__inner {
        display: flex;
        align-items: center;
        width: 100rem;
        margin: 0 auto;
        @include bp-down {
            width: 100%;
            flex-direction: column;
        }
    }
    &__logo {
        a {
            @include bp-down {
                display: block;
                width: 20rem;
                margin: 0 auto;
            }
        }
    }
    &__tel {
        margin-left: auto;
        a {
            font-weight: bold;
            color: $txt-default;
            font-size: 2.4rem;
            position: relative;
            &::before {
                position: absolute;
                @include icon;
                content: '\e803';
                left: -3rem;
                top: 60%;
                transform: translateY(-50%);
                color: $main-color;
            }
        }
    }
}
.drawer-menu-btn {
    background-color: $main-color;
    width: 10rem;
    height: 9rem;
    border-radius: 0 0 0 2rem;
    color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    font-weight: bold;
    padding-top: 3rem;
    transition: .3s;
    z-index: 7;
    &:hover {
        background-color: lighten($main-color, 10%);
        transition: .3s;
    }
    .bar {
        position: absolute;
        width: 4rem;
        height: .5rem;
        background-color: #fff;
        top: 2.5rem;
        left: 0;
        right: 0;
        margin: auto;
        transition: .3s;
        &::after {
            content: "";
            position: absolute;
            top: 1.5rem;
            left: 0;
            width: 2.5rem;
            height: .5rem;
            background-color: #fff;
            transition: .3s;
        }
    }
    &__txt {
        &::before {
            position: absolute;
            content: "OPEN";
            left: 3rem;
            bottom: 1.5rem;
            transition: .3s;
        }
    }
    &.open {
        .bar {
            transform: rotate(45deg);
            width: 2.5rem;
            top: 3rem;
            transition: .3s;
            &::after {
                transform: rotate(-90deg);
                top: 0;
                transition: .3s;

            }
        }
        .drawer-menu-btn__txt {
            &::before {
                content: "CLOSE";
                transition: .3s;
            }
        }
    }
    // .bar-short {
    //     position: absolute;
    //     width: 2.5rem;
    //     height: .5rem;
    //     background-color: #fff;
    // }
}

.footer-contact {
    background-image: url(./images/common/footer-contact-bg.jpg);
    background-size: cover;
    background-position: bottom center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    @include bp-down {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    &__sub {
        font-size: 3rem;
        color: #fff;
        margin-top: 2rem;
        font-weight: bold;
    }
    &__inner {
        margin-top: 8rem;
        @include bp-down {
            margin-top: 3rem;
        }
    }
    &__list {
        &__set {
            display: flex;
            gap: 3rem;
            @include bp-down {
                flex-direction: column;
            }
            li {
                background-color: #fff;
                border-radius: 1rem;
                padding: 4rem 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(100% - 6rem);
                @include bp-down {
                    width: 100%;
                }
                h3 {
                    color: $main-color;
                    font-size: 1.8rem;
                    font-weight: bold;
                    text-align: center;
                }
                &.line-link {
                    h3 {
                        color: $accent-color;
                    }
                }
                &.tel-link {
                    h3 {
                        color: $bright-color;
                    }
                }
            }
            &__btn {
                border-radius: 10rem;
                font-size: 1.6rem;
                text-align: center;
                width: 20rem;
                height: 4rem;
                background-color: $main-color;
                color: #fff;
                font-weight: bold;
                display: block;
                padding-top: 1rem;
                position: relative;
                margin-top: 2rem;
                &::after {
                    content: '\e80e';
                    position: absolute;
                    @include icon;
                    top: 50%;
                    right: 1.5rem;
                    transform: translateY(-50%);
                    font-size: 1rem;
                }
                .line-link & {
                    background-color: $accent-color;
                }
                .tel-link & {
                    background-color: $bright-color;
                }
                &:hover {
                    opacity: .8;
                    transition: .3s;
                }
            }
        }
    }
}
.footer-area {
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: relative;
    background-image: url(./images/common/yokohama-map.svg);
    background-size: 40%;
    background-position: 90% center;
    background-repeat: no-repeat;
    @include bp-down {
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-size: 140%;
        background-position: center;
    }
    &__inner {
        position: relative;
        z-index: 3;
    }
    &__sub {
        font-size: 3rem;
        margin-top: 2rem;
        font-weight: bold;
    }
    &__txt {
        margin-top: 2rem;
    }
    &__list {
        margin-top: 5rem;
        &__set {
            h3 {
                font-weight: bold;
                margin-top: 3rem;
            }
            ul {
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 0 1rem;
            }
        }
    }
}
.footer-nav {
    background-color: $main-color;
    @include bp-down {
        padding: 2rem;
    }
    &__inner {
        background-color: $main-dark;
    }
    &__set {
        &__list {
            display: flex;
            @include bp-down {
                flex-direction: column;
                gap: 0;
            }
            li {
                width: calc(100% / 7);
                @include bp-down {
                    width: 100%;
                    & + li {
                        border-top: .1rem solid #fff;
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    font-weight: bold;
                    padding: 1rem 2rem;
                    color: #fff;
                    font-size: 1.3rem;
                    @include bp-down {
                        align-items: flex-start;
                        padding: 1.5rem;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.footer-comp-info {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .ttl2 {
        margin-top: 3rem;
    }
    &__body {
        margin-top: 2rem;
        p {
            font-weight: bold;
        }
    }
}
.footer-copyright {
    background-color: $main-color;
    @include bp-down {
        padding-bottom: 9rem;
    }
    p {
        color: #fff;
        font-size: 1.1rem;
        padding: 1rem 0;
    }
}
.under-page-header {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $bg-lightest;
    @include bp-down {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    &__en {
        color: $main-color;
        font-size: 3.6rem;
        font-weight: bold;
        @include bp-down {
            font-size: 2.4rem;
        }
    }
    &__ja {
        font-size: 3.6rem;
        font-weight: bold;
        margin-top: 4rem;
        position: relative;
        @include bp-down {
            font-size: 2.4rem;
            margin-top: 2rem;
        }
        &::before {
            content: "";
            position: absolute;
            width: 6rem;
            height: .3rem;
            background-color: $bright-color;
            top: -2rem;
            left: 0;
            @include bp-down {
                top: -1rem;
                width: 4rem;
            }
        }
    }
}
.sp-menu {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;
    &__inner {
        display: flex;
    }
    &__block {
        width: calc(100% / 4);
        background-color: $main-dark;
        text-align: center;
        color: #fff;
        padding-top: 1rem;
        padding-bottom: 1rem;
        position: relative;
        &.line {
            background-color: $accent-color;
        }
        &.menu-btn {
            background-color: $main-color;
            >p {
                margin-top: 4.5rem;
            }
        }
        i {
            font-size: 2.4rem;
        }
        p {
            font-size: 1.2rem;
        }
    }
}
.sp-menu-bar {
    position: relative;
    display: block;
    // content: "";
    position: absolute;
    width: 3rem;
    height: .3rem;
    background-color: #fff;
    top: 3rem;
    right: 0;
    left: 0;
    margin: auto;
    transition: .3s;
    &::after {
        content: "";
        position: absolute;
        width: 2rem;
        height: .3rem;
        background-color: #fff;
        top: 1rem;
        transform: translateY(-50%);
        right: 0;
        // left: 0;
        margin: auto;
        transition: .3s;
    }
    &::before {
        // content: "";
        // position: absolute;
        // width: 3rem;
        // height: .3rem;
        // background-color: #fff;
        // top: 2rem;
        // right: 0;
        // left: 0;
        // margin: auto;
        // transition: .3s;
    }
}
.menu-btn {
    &.open {
        .sp-menu-bar {
            transform: rotate(45deg);
            top: 3rem;
            transition: .3s;
            &::after {
                transition: .3s;
                width: 3rem;
                transform: rotate(-90deg);
                top: 0;
            }
        }
    }
}
.sp-drawer-menu {
    position: fixed;
    background-color: $main-color;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: .5rem solid $main-color;
    transform: translateY(100%);
    transition: .3s;
    overflow-y: scroll;
    padding-bottom: 10rem;
    &.open {
        transform: translateY(0);
    }
    >nav {
        padding: 3rem;
    }
    &__inner {
        overflow-y: scroll;
        padding: 0 3rem 3rem 3rem ;
    }
    &__ttl {
        background-color: $main-dark;
        color: #fff;
        padding: 2rem;
        border-radius: 2rem 2rem 0 0;
    }
    &__logo {
        background-color: #fff;
        padding: 1rem;
        img {
            width: 20rem;
            margin: 0 auto;
            display: block;
        }
    }
    &__set {
        background-color: #fff;
        border-radius: 0 0 2rem 2rem;
        overflow: hidden;
        li {
            & + li {
                border-top: .1rem solid $main-dark;
            }
            a {
                color: $main-dark;
                padding: 2rem;
                display: block;
                font-weight: bold;
            }
        }
    }
}
.pc-drawer-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $main-dark;
    background-image: url(./images/common/drawer-bg.jpg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 7;
    padding-top: 6rem;
    padding-bottom: 6rem;
    transform: translateX(100%);
    transition: .3s;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display:none;
    }
    &.open {
        transform: translateX(0);
        transition: .3s;
    }
    &__wrapper {
        border-radius: 2rem;
        overflow: hidden;
    }
    &__inner {
        background-color: $main-dark;
        padding: 4rem;
    }
    &__ttl {
        font-size: 2.4rem;
        font-weight: bold;
        color: #fff;
        padding: 2rem;
        background-color: $main-color;
        text-align: center;
    }
    &__set {
        border-radius: 1.6rem;
        overflow: hidden;
        &__item {
            & + li {
                margin-top: .4rem;
            }
            a {
                display: block;
                padding: 2rem;
                font-size: 1.8rem;
                font-weight: bold;
                background-color: #fff;
                color: $txt-default;
            }
        }
    }
}
.error-page-content {
    &__link {
        margin-top: 4rem;
    }
    .list-dots {
        margin-top: 2rem;
    }
    >p {
        margin-top: 2rem;
    }
}