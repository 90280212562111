@use "../variables" as *;
@use "../mixins" as *;

.staff-page-loop-slider {
    margin-top: 10rem;
    @include bp-down {
        margin-top: 4rem;
    }
}
.staff-block {
    margin-top: 10rem;
    @include bp-down {
        margin-top: 5rem;
    }
    &__inner {
        display: flex;
        gap: 4rem;
        margin-top: 5rem;
        @include bp-down {
            flex-direction: column;
            gap: 2rem;
            margin-top: 3rem;
        }
    }
    &__thumb {
        width: 46rem;
        border-radius: 2rem;
        overflow: hidden;
        @include bp-down {
            width: 100%;
        }
    }
    &__txt {
        width: 50rem;
        @include bp-down {
            width: 100%;
        }
        .ttl-dots {
            margin-top: 2rem;
        }
        .list-dots {
            margin-top: 2rem;
        }
        .hoby {
            margin-top: 4rem;
        }
        .hoby-txt {
            margin-top: 2rem;
        }
    }
}