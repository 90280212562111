@use "../variables" as *;
@use "../mixins" as *;

.btn {
    border-radius: 10rem;
    text-align: center;
    width: 30rem;
    height: 4.5rem;
    background-color: $main-color;
    color: #fff;
    font-weight: bold;
    display: block;
    padding-top: 1.5rem;
    position: relative;
    &::after {
        content: '\e80e';
        position: absolute;
        @include icon;
        top: 50%;
        right: 2.5rem;
        transform: translateY(-50%);
        font-size: 1rem;
    }
}